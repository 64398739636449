// Import the functions you need from the SDKs you need
import firebase from "firebase/app";
import "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC9E522gqhkc_av0U9kIMhOLMb_D_5_VBQ",
  authDomain: "commercial-kitchen-timer.firebaseapp.com",
  projectId: "commercial-kitchen-timer",
  storageBucket: "commercial-kitchen-timer.appspot.com",
  messagingSenderId: "129166446844",
  appId: "1:129166446844:web:9ca086e7430d3cb0200779",
  databaseURL:
    "https://commercial-kitchen-timer-default-rtdb.asia-southeast1.firebasedatabase.app/",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
// export const db = getFirestore(app);
export default firebase;
