import { Button } from "@blueprintjs/core"
import React, { useEffect, useMemo, useState } from "react"
import { connect } from "react-redux"
import {
  playBellSound,
  playRemoveSound,
  stopBellSound,
} from "../common/sounds/soundPlays"
import { removeTime } from "../common/storage/database"
import { removeTimeAction } from "../common/store/action"
import { TimeState } from "../common/store/reducer"
import { hourFormat, secToMinDisplay } from "../common/time/timeConverter"
import styles from "./index.module.scss"

interface Props {
  time: TimeState
  removeTimeAction: (starTime: string) => void
}
const getTimeleft = (endTime: Date) => {
  let currentTime = new Date()
  let timeLeftSec = Math.floor(
    (endTime.getTime() - currentTime.getTime()) / 1000
  )
  if (timeLeftSec < 0) {
    return 0
  }
  return timeLeftSec < 0 ? 0 : timeLeftSec
}

const Timing = ({ time, removeTimeAction }: Props) => {
  const startTime = new Date(time.startTime)
  const endTime = useMemo(() => new Date(time.endTime), [time.endTime])

  const [timeInterval, setTimeInterval] = useState(getTimeleft(endTime))
  const [hasPlayWarning, setHasPlayedWarning] = useState(false)

  useEffect(() => {
    setTimeout(() => setTimeInterval(getTimeleft(endTime)), 1000)

    if (timeInterval === 0) {
      const bellSoundId = playBellSound()
      const intervalId = setInterval(() => {
        playBellSound(bellSoundId)
      }, 3000)
      return () => {
        clearInterval(intervalId)
        stopBellSound(bellSoundId)
      }
    }
    if (!hasPlayWarning && timeInterval <= 10) {
      playBellSound()
      setHasPlayedWarning(true)
    }
  }, [timeInterval])

  const removeTimeHandleOnclick = () => {
    playRemoveSound()
    removeTime(time.key)
    removeTimeAction(time.startTime)
  }

  return (
    <div
      className={`${styles.wrapper} ${
        timeInterval === 0 ? styles.backgroundRed : styles.backgroundColour
      }`}
    >
      <span className={styles.length}>{time.lengthTime}</span>
      <Button
        className={styles.removeButton}
        text={hourFormat(startTime)}
        onClick={removeTimeHandleOnclick}
      ></Button>
      <span className={styles.remaining}>{secToMinDisplay(timeInterval)}</span>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return { timeStates: state.time.timeStates }
}
const mapDispatchToProps = (dispatch: any) => {
  return {
    removeTimeAction: (startTime: string) => {
      dispatch(removeTimeAction(startTime))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Timing)
