import React from "react"
import { Route, Routes } from "react-router-dom"
import Header from "../header"
import Information from "../Information"

import { SignIn } from "../login"
import Timer from "../timer"

const App = () => {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/signin" element={<SignIn />} />
        <Route path="/timer" element={<Timer />} />
        <Route path="*" element={<Information />} />
      </Routes>
    </div>
  )
}

export default App
