import { Button, InputGroup, Intent } from "@blueprintjs/core"
import firebase from "firebase"
import { useState } from "react"
import { AppToaster } from "../common/toaster/toaster"
import Styles from "./styles.module.scss"

export const ResetPassword = () => {
  const [inputValue, setInputValue] = useState("")
  const resetPasswordBtn = (
    <Button
      small={true}
      intent={Intent.PRIMARY}
      onClick={() => sendResetPassword()}
    >
      Reset password
    </Button>
  )
  const sendResetPassword = () => {
    firebase
      .auth()
      .sendPasswordResetEmail(inputValue)
      .then(() => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          timeout: 2000,
          message: "Password reset email sent",
        })
        setInputValue("")
      })
      .catch((error) => {
        AppToaster.show({
          intent: Intent.DANGER,
          timeout: 2000,
          message: error.message,
        })
      })
  }

  const onChangeResetPasswordInput = (e: any) => {
    e.preventDefault()
    setInputValue(e.target.value)
  }
  return (
    <div>
      <InputGroup
        className={Styles.passwordRestInput}
        placeholder="email address to reset the password "
        rightElement={resetPasswordBtn}
        large={true}
        value={inputValue}
        onChange={(e) => onChangeResetPasswordInput(e)}
      />
    </div>
  )
}
