import { Button, Intent } from "@blueprintjs/core"
import firebase from "firebase"
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { addUserAction } from "../common/store/action"
import { UserState } from "../common/store/reducer"
import styles from "./index.module.scss"

interface Props {
  addUser: (user: firebase.User | null) => void
  userState: UserState
}

const Header = (props: Props) => {
  const navigate = useNavigate()
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      props.addUser(user)
    })

    return () => unsubscribe()
  }, [])

  const handleClickSignIn = () => {
    navigate("/signIn")
  }

  const handleClickSignOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        navigate("/infomation")
      })
      .catch((error) => {
        // An error happened.
      })
  }

  return (
    <div className={styles.header}>
      <div className={styles.container}>
        <div className={styles.headerText}>Commercial Kitchen Timer v4</div>
        {props.userState.isEmailVerified && props.userState.isSignedIn && (
          <Link to="/timer">Timer</Link>
        )}
        <Link to="/information">Info</Link>
        {!props.userState.isSignedIn && (
          <Button
            intent={Intent.PRIMARY}
            onClick={handleClickSignIn}
            className={styles.signInButton}
            small={true}
          >
            Sign In
          </Button>
        )}
        {props.userState.isSignedIn && (
          <Button
            onClick={handleClickSignOut}
            intent={Intent.DANGER}
            className={styles.signInButton}
            small={true}
          >
            Sign out
          </Button>
        )}
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    addUser: (user: firebase.User | null) => {
      dispatch(addUserAction(user))
    },
  }
}
const mapStateToProps = (state: any) => {
  return { userState: state.time.userState }
}
export default connect(mapStateToProps, mapDispatchToProps)(Header)
