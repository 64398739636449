import firebase from "../firebase-config"

export const setTime = (lengthTime: number) => {
  const currentDate = new Date()
  return firebase
    .database()
    .ref("users/" + firebase.auth().currentUser?.uid + "/timer")
    .push({
      starttime: currentDate.toISOString(),
      lengthtime: lengthTime,
    })
}

export const getTime = () => {
  const timersRef = firebase
    .database()
    .ref("users/" + firebase.auth().currentUser?.uid + "/timer")
  return timersRef
    .get()
    .then((times) => {
      if (times.exists()) {
        return times.val()
      } else {
        console.log("No data available")
      }
    })
    .catch((error) => {
      console.error(error)
    })
}
export const removeTime = (key: string) => {
  const timerRefString =
    "users/" + firebase.auth().currentUser?.uid + "/timer/" + key
  const timersRef = firebase.database().ref(timerRefString)
  timersRef.remove()
}

export const sentFeedback = (message: string) => {
  const currentDate = new Date()
  return firebase
    .database()
    .ref("feedback/" + firebase.auth().currentUser?.uid)
    .push({
      message: message,
      createdTime: currentDate.toISOString(),
    })
}
