import React, { useEffect } from "react"
import { connect } from "react-redux"
import Time from "../time"
import { TimeState, UserState } from "../common/store/reducer"
import { addTimeAction, removeAllTimeAction } from "../common/store/action"
import styles from "./index.module.scss"
import Timing from "../timing"
import { getTime as getTimes } from "../common/storage/database"
import firebase from "firebase"
import { Card, Elevation } from "@blueprintjs/core"

interface Props {
  timeStates: TimeState[]
  addTime?: (key: string, lengthTime: number, startTime: Date) => void
  userState: UserState
  removeAllTime?: () => void
}

const Timer = (props: Props) => {
  useEffect(() => {
    var timeRef = firebase
      .database()
      .ref("users/" + props.userState.user.uid + "/timer")
    timeRef.on("value", (snapshot) => {
      const times = snapshot.val()
      props.removeAllTime?.()
      for (const property in times) {
        props.addTime?.(
          property,
          times[property].lengthtime,
          times[property].starttime
        )
      }
    })
  }, [])

  useEffect(() => {
    if (props.userState.isSignedIn) {
      if (props.timeStates.length === 0) {
        getTimes().then((times) => {
          for (const property in times) {
            props.addTime?.(
              property,
              times[property].lengthtime,
              times[property].starttime
            )
          }
        })
      }
    }
  }, [props.userState.isSignedIn])

  const getTimings = () => {
    return props.timeStates.map((t) => (
      <Card
        key={t.startTime}
        className={styles.card}
        interactive={true}
        elevation={Elevation.TWO}
      >
        <Timing time={t} />
      </Card>
    ))
  }

  return (
    <div>
      <Time />
      <>{getTimings()}</>
    </div>
  )
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    addTime: (key: string, lengthTime: number, startTime: Date) => {
      dispatch(addTimeAction(key, lengthTime, startTime))
    },
    removeAllTime: () => {
      dispatch(removeAllTimeAction())
    },
  }
}

const mapStateToProps = (state: any) => {
  return { timeStates: state.time.timeStates, userState: state.time.userState }
}
export default connect(mapStateToProps, mapDispatchToProps)(Timer)
