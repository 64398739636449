export const secToMinDisplay = (totalSecs: number) => {
  let mins = Math.floor(totalSecs / 60);
  let secs = totalSecs - mins * 60;
  return `${mins.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}:${secs.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}`;
};

export const hourFormat = (date: Date) => {
  let hours = date.getHours();
  let mins = date.getMinutes();
  let ampm = hours > 12 ? "PM" : "AM";

  return `${hours.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}:${mins.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })} ${ampm}`;
};
