import { configureStore } from "@reduxjs/toolkit"
import { TimerReducer } from "./reducer"

const store = configureStore({
  reducer: {
    time: TimerReducer,
  },
  devTools: true,
})
export default store
