import pop from "./wav/pop.wav"
import bell from "./wav/bell.wav"
import bellecho from "./wav/bellecho.wav"
import remove from "./wav/remove.wav"
import { Howl } from "howler"

export const clickButtonSound = () => {
  var sound = new Howl({
    src: [pop],
  })

  sound.play()
}

const bellSound = new Howl({
  src: [bell],
})
export const playBellSound = (id?: number) => {
  if (id) {
    bellSound.fade(1, 0, 3, id)
    bellSound.play(id)
  }
  return bellSound.play()
}
export const stopBellSound = (id?: number) => {
  bellSound.stop(id)
}

export const playRemoveSound = () => {
  let sound = new Howl({
    src: [remove],
  })

  sound.play()
}
