import { Card, Elevation, Intent } from "@blueprintjs/core"
import firebase from "firebase"
import { useState } from "react"
import { connect } from "react-redux"
import { UserState } from "../common/store/reducer"
import { AppToaster } from "../common/toaster/toaster"
import { FeedbackPanel } from "../feedback"
import Timer from "./Timer.png"
import style from "./style.module.scss"
const todo = ["Visual effects", "anminations", "Customisable time button"]
const v3 = [
  "10 second warning bell",
  "different sounds for end of timer",
  "Secure https",
]
const v4 = ["Sync between devices with the same login"]

interface Props {
  userState?: UserState
}

const createList = (list: Array<string>) => {
  return list.map((d) => {
    return <li key={d}>{d}</li>
  })
}

const Information = ({ userState }: Props) => {
  const [sentEmailVerification, setsentEmailVerification] = useState(false)

  const sendEmailVerificationLink = () => {
    firebase
      .auth()
      .currentUser?.sendEmailVerification()
      .then(() => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          timeout: 2000,
          message: "Email Verification sent",
        })
        setsentEmailVerification(true)
      })
  }

  return (
    <>
      {!userState?.isEmailVerified && userState?.isSignedIn && (
        <Card interactive={true} elevation={Elevation.TWO}>
          Please verify your email address before processing. Refresh the page
          once verified
          {!sentEmailVerification && (
            <a onClick={sendEmailVerificationLink}>
              {" "}
              Send email verification link to meail.
            </a>
          )}
        </Card>
      )}
      <Card interactive={true} elevation={Elevation.TWO}>
        <h2 className={style.heading}>Information</h2>
        <div className={style.paragraph}>
          This Web timer is used for commerical kitchen that needs a persisting
          timer. Hence the reason you need to create an account and log before
          use. When you are logged in a feedback panel should show below. Dont
          forget to log bugs and ask for feature that might suit your cookery.
          From v4 all devices should be in sync. They must be logged in with the
          same login betweem devices. Side note its a web site so if something
          looks wrong just refresh the page.
          <br />
          <br />
          <strong>
            Email the dev team bugs and feature request on GGAppPub@gmail.com
          </strong>
          <br />
          <br />
          V4
          <ul>{createList(v4)}</ul>
          V3
          <ul>{createList(v3)}</ul>
          <br />
          Things we are working are
          <ul>{createList(todo)}</ul>
        </div>
        <img src={Timer} alt="Logo" width="30%" />
        {userState?.isSignedIn && <FeedbackPanel />}
      </Card>
    </>
  )
}
const mapStateToProps = (state: any) => {
  return { userState: state.time.userState }
}
export default connect(mapStateToProps)(Information)
