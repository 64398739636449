import { Button, getKeyCombo, Intent } from "@blueprintjs/core"
import React from "react"
import { connect } from "react-redux"
import { clickButtonSound } from "../common/sounds/soundPlays"
import { setTime } from "../common/storage/database"
import { addTimeAction } from "../common/store/action"
import styles from "./index.module.scss"

const arrayTimes = [1, 2, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]

interface Props {
  addTimeAction: (key: string, lengthTime: number) => void
}

const Time = (props: Props) => {
  const handleClick = (lengthTime: number) => {
    clickButtonSound()
    let key: string = setTime(lengthTime).key ?? ""
    props.addTimeAction(key, lengthTime)
  }

  const getTimes = () => {
    return arrayTimes.map((d) => (
      <Button
        intent={Intent.WARNING}
        key={d}
        onClick={() => handleClick(d)}
        className={styles.timeButton}
      >
        {d}
      </Button>
    ))
  }

  return <div>{getTimes()}</div>
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    addTimeAction: (key: string, lengthTime: number) => {
      dispatch(addTimeAction(key, lengthTime, new Date()))
    },
  }
}

export default connect(null, mapDispatchToProps)(Time)
