import firebase, { auth } from "../common/firebase-config"
import { StyledFirebaseAuth } from "react-firebaseui"
import { ResetPassword } from "../resetpassword"

const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup",
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    { provider: firebase.auth.EmailAuthProvider.PROVIDER_ID },
    { provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID },
  ],
  signInSuccessUrl: "/information",
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => true,
  },
}
export const SignIn = () => {
  return (
    <div>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
      <ResetPassword />
    </div>
  )
}
