import { Button, Intent, Position, TextArea, Toaster } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import { sentFeedback } from "../common/storage/database"
import { AppToaster } from "../common/toaster/toaster"
import style from "./style.module.scss"

const minCharacter = 180
const feedbackValidatiionMessage = `Must be greater then ${minCharacter}  characters`

export const FeedbackPanel = () => {
  const [textArea, setTextArea] = useState("")
  const [enableFeedback, setEnableFeedback] = useState(false)
  const onChangeTextArea = (e: any) => {
    e.preventDefault()
    setTextArea(e.target.value)
  }

  const onClick = () => {
    if (textArea.length >= minCharacter) {
      let result = sentFeedback(textArea)
      if (result?.key) {
        setTextArea("")
        AppToaster.show({
          intent: Intent.SUCCESS,
          timeout: 2000,
          message: "feedback sent",
        })
      } else {
        AppToaster.show({
          intent: Intent.DANGER,
          timeout: 2000,
          message: "feedback error",
        })
      }
      return
    }
  }

  useEffect(() => {
    setEnableFeedback(textArea.length >= minCharacter)
  }, [textArea])

  return (
    <div className={style.main}>
      <h3>Feedback</h3>
      <TextArea
        className={style.textArea}
        small={true}
        intent={enableFeedback ? Intent.PRIMARY : Intent.DANGER}
        onChange={(e) => onChangeTextArea(e)}
        value={textArea}
      ></TextArea>
      <Button
        className={style.sendButton}
        intent={enableFeedback ? Intent.PRIMARY : Intent.DANGER}
        disabled={!enableFeedback}
        text={enableFeedback ? "Send For Feedback" : feedbackValidatiionMessage}
        onClick={() => onClick()}
      />
    </div>
  )
}
