import { createReducer } from "@reduxjs/toolkit"
import {
  addTimeAction,
  addUserAction,
  removeAllTimeAction,
  removeTimeAction,
} from "./action"

export interface TimeState {
  key: string
  startTime: string
  lengthTime: number
  endTime: string
}
export interface User {
  displayName: string
  email: string
  emailVerified: boolean
  uid: string
}
export interface UserState {
  user: User
  isSignedIn: boolean
  isEmailVerified: boolean
}
export interface AppState {
  timeStates: TimeState[]
  userState: UserState
}
const initialStateTime: AppState = {
  timeStates: [],
  userState: {
    user: {} as User,
    isSignedIn: false,
    isEmailVerified: false,
  },
}

export const TimerReducer = createReducer(initialStateTime, (builder) => {
  builder.addCase(addTimeAction, (state, action) => {
    if (!state.timeStates.some((d) => d.key === action.payload.key)) {
      state.timeStates = [...state.timeStates, action.payload]
    }
  })

  builder.addCase(removeTimeAction, (state, action) => {
    state.timeStates = state.timeStates.filter(
      (d) => d.startTime !== action.payload.startTime
    )
  })
  builder.addCase(removeAllTimeAction, (state, action) => {
    state.timeStates = []
  })

  builder.addCase(addUserAction, (state, action) => {
    state.userState = action.payload
  })
})
