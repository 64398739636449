import { createAction } from "@reduxjs/toolkit"
import firebase from "firebase"
import { User } from "./reducer"
export const addTimeAction = createAction(
  "timeadd",
  (key: string, lengthTime: number, startTime: Date) => {
    let date = new Date(startTime)
    return {
      payload: {
        startTime: date.toISOString(),
        lengthTime: lengthTime,
        endTime: new Date(date.getTime() + lengthTime * 60000).toISOString(),
        key,
      },
    }
  }
)
export const removeTimeAction = createAction(
  "time/remove",
  (startTime: string) => {
    return {
      payload: {
        startTime: startTime,
      },
    }
  }
)

export const removeAllTimeAction = createAction("time/allRemove", () => {
  return {
    payload: {},
  }
})

export const addUserAction = createAction(
  "addUser",
  (firebaseUser: firebase.User | null) => {
    return {
      payload: {
        user: {
          displayName: firebaseUser?.displayName,
          email: firebaseUser!?.email,
          emailVerified: firebaseUser?.emailVerified,
          uid: firebaseUser!?.uid,
        } as User,

        isSignedIn: firebaseUser != null ?? false,
        isEmailVerified: firebaseUser?.emailVerified ?? false,
      },
    }
  }
)
